<template>
  <div id="app">
    <Nav />
    <div class="pageMain">
      <img class="league-img" src="@/assets/league/banner.png" />
      <!-- 头部 -->
      <div class="league-top">
        <div class="league-headline">
          <div class="league-top-left"></div>
          <div class="league-top-txt">苏州市民族管弦乐<br />艺术团队联合会</div>
        </div>
        <div class="league-txt mt-10">
          苏州市民族管弦乐艺术团队联合会是由苏州民族管弦乐团等单位发起、苏州市文化和民政部门批准，为苏州民族器乐艺术作曲、理论、指挥、教育、演奏等人才的培养平台和非职业民族器乐爱好者及民乐社团的传播交流平台，由中国广播民族乐团艺术总监兼首席指挥、苏州民族管弦乐团艺术总监兼首席指挥彭家鹏担任会长。
        </div>
        <div class="league-txt mt-35">
          联合会的成立，对于整合江南丝竹在新时代的品牌效应，进一步构建完善我市公共文化服务体系，满足人民群众在新时代的精神文化需求，具有重要的意义。
        </div>
        <div class="league-txt mt-35">
          会长彭家鹏表示，联合会的成立体现了苏州对新时代民族音乐文化事业再出发的殷切期望。自己将与理事会班子成员一起，用创新机制、用满腔热情、用不懈奋斗，共同把联合会建设好、发展好，打造新时代中国苏州民族音乐文化品牌。
        </div>
        <img class="top-img mt-10" src="@/assets/league/lea-1.png" />
      </div>
      <!-- 中间 -->
      <div class="league-content">
        <div class="league-list flex between">
          <div
            class="league-list-hz"
            v-for="(item, index) in titleList"
            :key="index"
            :class="activeIndex == item.id ? 'actives' : ''"
            @click="tabLea(item.id)"
          >
            <div class="league-list-txt">{{ item.name }}</div>
            <div class="league-list-txt">{{ item.name2 }}</div>
          </div>
        </div>
        <div v-if="activeIndex != 3">
          <div class="league-title">相关资讯</div>
          <div class="league-information-ul flex">
            <div
              class="information-li"
              v-for="(item, index) in informationList"
              :key="index"
            >
              <img class="information-img" :src="item.imgurl" alt="" />
              <div class="information-txt1 ellipsis-2">
                {{ item.title }}
              </div>
              <div class="information-txt2">{{ item.time }}</div>
            </div>
          </div>
          <div class="league-title">
            {{ activeIndex == 1 ? "展演风采" : "大运周风采" }}
          </div>
          <swiper ref="mySwiper" id="homeBanner" :options="bannerOptions">
            <swiper-slide v-for="(img, index) in swiperList" :key="index">
              <img :src="img.imgurl" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <div class="exhibition-ul">
            <div
              class="exhibition-li"
              v-for="(item, index) in mienList"
              :key="index"
            >
              <img class="exhibition-li-img" :src="item.imgurl" alt="" />
              <div class="exhibition-li-hz">
                <p class="exhibition-li-p">{{ item.title }}</p>
                <p class="exhibition-li-p">{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 新年音乐会 -->
        <div class="league-concert" v-if="activeIndex == 3">
          <!-- <div class="league-concert-title">
            苏州民族管弦乐团新年音乐会<br />《丝竹里的江南》奏响苏州湾大剧院
          </div>
          <div class="league-concert-txt">
            新年伊始，东太湖迎来春之声。1月1日晚，由著名指挥家、苏州民族管弦乐团艺术总监兼首席指挥彭家鹏执棒，著名二胡演奏家、乐团艺术指导朱昌耀，著名大提琴演奏家康乔瑄与苏州民族管弦乐团的青年演奏家们共同演奏的国家艺术基金2020舞台艺术创作资助项目“丝竹里的江南”——苏州民族管弦乐团新年音乐会在苏州湾大剧院奏响。本场音乐会是“2021首届苏州湾歌剧舞剧节”的重要演出，在新年的欢乐时刻，为市民献上兼容并蓄的江南音乐听觉盛宴、送上美好的新年祝福。
          </div> -->
          <img
            class="league-concert-img"
            src="@/assets/league/lea-2.png"
            alt=""
          />
          <img
            class="league-concert-img"
            src="@/assets/league/lea-3.png"
            alt=""
          />
          <img
            class="league-concert-img"
            src="@/assets/league/lea-4.png"
            alt=""
          />
        </div>
      </div>
      <!-- 尾部 -->
      <div class="league-bottom">
        <div class="league-title flex between v-center">
          <span>联合会动态</span>
          <span class="league-title-span">查看更多&nbsp;&gt;</span>
        </div>
        <div class="league-dynamic-ul">
          <div
            class="league-dynamic-li"
            v-for="(item, index) in dynamicList"
            :key="index"
          >
            <img class="dynamic-li-img" :src="item.imgurl" alt="" />
            <div class="information-txt1">
              {{ item.title }}
            </div>
            <div class="information-txt2 ellipsis-2">
              {{ item.name }}
            </div>
            <div class="information-txt2">{{ item.time }}</div>
          </div>
        </div>
        <div class="league-title">展演·活动征集</div>
        <div class="activities-ul">
          <div
            class="activities-li flex"
            v-for="(item, index) in activitiesList"
            :key="index"
          >
            <img class="activities-li-img" :src="item.imgurl" alt="" />
            <div>
              <div class="information-txt1 activities-li-txt ellipsis-2">
                {{ item.title }}
              </div>
              <div class="information-txt2">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      isIndex: false,
      activeIndex: 1,
      titleList: [
        {
          id: 1,
          name: "苏州民族",
          name2: "音乐展演",
        },
        {
          id: 2,
          name: "大运河",
          name2: "文化传承展示周",
        },
        {
          id: 3,
          name: "苏州市",
          name2: "新年音乐会",
        },
      ],
      bannerOptions: {
        autoHeight: true,
        pagination: {
          el: "#homeBanner .swiper-pagination",
          clickable: true,
        },
      },
      informationList: [],
      informationList1: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-5.png"),
          title: "首届江苏·苏州民族音乐展演在苏州民族管弦乐团音乐厅正式开幕.",
          time: "2022.09.20",
        },
        {
          id: 2,
          imgurl: require("../../assets/league/lea-17.png"),
          title: "首届江苏•苏州民族音乐展演圆满闭幕",
          time: "2020.12.15",
        },
      ],
      informationList2: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-18.png"),
          title: "首届运河情 江南韵 大运河民族音乐文化传承展示周正式开幕",
          time: "2021.12.25",
        },
        {
          id: 2,
          imgurl: require("../../assets/league/lea-19.png"),
          title: "首届江苏•苏州民族音乐展演圆满闭幕",
          time: "2020.12.15",
        },
      ],
      swiperList: [],
      swiperList1: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-6.png"),
        },
        {
          id: 2,
          imgurl: require("../../assets/league/lea-7.png"),
        },
        {
          id: 3,
          imgurl: require("../../assets/league/lea-8.png"),
        },
        {
          id: 4,
          imgurl: require("../../assets/league/lea-9.png"),
        },
      ],
      swiperList2: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-20.png"),
        },
        {
          id: 2,
          imgurl: require("../../assets/league/lea-21.png"),
        },
        {
          id: 3,
          imgurl: require("../../assets/league/lea-22.png"),
        },
        {
          id: 4,
          imgurl: require("../../assets/league/lea-23.png"),
        },
        {
          id: 5,
          imgurl: require("../../assets/league/lea-24.png"),
        },
      ],
      mienList: [],
      mienList1: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-10.png"),
          title: "第一届",
          name: "苏州民族音乐展演回顾",
        },
        {
          id: 2,
          imgurl: require("../../assets/league/lea-11.png"),
          title: "第二届",
          name: "苏州民族音乐展演回顾",
        },
      ],
      mienList2: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-25.png"),
          title: "运河情 江南韵",
          name: "大运河民族音乐文化传承展示周",
        },
      ],
      dynamicList: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-12.png"),
          title:
            "全国首家市级民族管弦乐行业协会“苏州民族管弦乐艺术团队联合会”揭牌",
          name: "全国首家市级民族管弦乐行业协会“苏州民族管弦乐艺术团队联合会”揭牌...",
          time: "2022.09.12",
        },
        {
          id: 2,
          imgurl: require("../../assets/league/lea-13.png"),
          title: "苏州成立全国首家民族管弦乐艺术团队联合会",
          name: "12月15日，由苏州民族管弦乐团等单位发起、苏州市文化和民政部门批准的苏州市民族管弦乐艺...",
          time: "2021.12.15",
        },
      ],
      activitiesList: [
        {
          id: 1,
          imgurl: require("../../assets/league/lea-14.png"),
          title: "通知丨第二届苏州市青少年民族器乐展演活动开始报名",
          time: "2022.07.08",
        },
        {
          id: 2,
          imgurl: require("../../assets/league/lea-15.png"),
          title: "2022年度苏州民族管弦乐团考级开始报名！",
          time: "2022.03.08",
        },
        {
          id: 3,
          imgurl: require("../../assets/league/lea-16.png"),
          title: "领奖通知丨2021首届苏州市青少年民族器乐大赛",
          time: "2021.10.05",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.tabLea(1)
  },
  methods: {
    tabLea(val) {
      this.activeIndex = val;
      if (val == 1) {
        this.informationList = this.informationList1;
        this.swiperList = this.swiperList1;
        this.mienList = this.mienList1;
      } else if (val == 2) {
        this.informationList = this.informationList2;
        this.swiperList = this.swiperList2;
        this.mienList = this.mienList2;
      }
    },
  },
};
</script>



<style lang="scss" scoped>
.orc-img {
  max-width: 100%;
}
.league-img {
  display: block;
  width: 100%;
  height: 133px;
}
.league-top {
  width: 100%;
  padding: 15px 16px;
  box-sizing: border-box;
  background-color: #f2f6f7;
}
.league-headline {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
}
.league-top-left {
  width: 5px;
  height: 38px;
  background-color: #b59d8f;
  margin-right: 9px;
  flex-shrink: 0;
}
.league-top-txt {
  color: #444444;
  font-size: 15px;
  line-height: 19px;
  font-weight: Bold;
  letter-spacing: 8px;
}
.league-txt {
  color: #5b5b5b;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: justify;
}
.league-content {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
}
.league-list-hz {
  width: 100px;
  height: 42px;
  background-color: #f2f6f7;
  text-align: center;
  font-size: 14px;
  color: #666666;
  border-radius: 4px;
  font-family: Source Han Sans CN;
}
.league-list-hz:nth-child(2) {
  width: 110px;
}
.league-list-txt {
  line-height: 21px;
}
.actives {
  background-color: #fff2e5;
  color: #835c46;
  box-shadow: 0px 2px 0px 0px #835c46;
}
.league-title {
  color: #464646;
  font-size: 15px;
  margin: 22px 0 12px;
  font-family: Source Han Sans CN;
  .league-title-span {
    font-size: 12px;
    color: #b3b3b3;
  }
}
// 轮播图
#homeBanner {
  border-radius: 8px;
  margin-bottom: 24px;
}
.league-information-ul {
  width: calc(100% + 16px);
  height: 242px;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
}
.information-li {
  width: 244px;
  height: 242px;
  background-color: #f6f6f6;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 12px;
}
.information-img {
  width: 100%;
  height: 150px;
  display: block;
}
.information-txt1 {
  font-size: 14px;
  color: #4b4b4b;
  line-height: 20px;
  margin: 10px 10px 4px;
  // height: 40px;
}
.information-txt2 {
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  margin: 0 10px;
}
.exhibition-ul {
  width: 100%;
  .exhibition-li {
    width: 100%;
    height: 190px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin-bottom: 8px;
    .exhibition-li-img {
      width: 100%;
      height: 100%;
    }
    .exhibition-li-hz {
      position: absolute;
      top: 46px;
      left: 16px;
      color: #fff;
      font-size: 14px;
      .exhibition-li-p {
        line-height: 18px;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.league-bottom {
  width: 100%;
  padding: 0 16px 30px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #f2f6f7;
}
.league-dynamic-ul {
  width: 100%;
  .league-dynamic-li {
    width: 100%;
    margin-bottom: 12px;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    overflow: hidden;
    padding-bottom: 8px;
    .dynamic-li-img {
      width: 100%;
      height: 200px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.activities-ul {
  width: 100%;
  .activities-li {
    width: 100%;
    height: 70px;
    margin-bottom: 12px;
    .activities-li-img {
      width: 130px;
      height: 70px;
      border-radius: 4px;
      flex-shrink: 0;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.activities-li-txt {
  margin: 2px 0 4px 10px;
}
// 新年音乐会
.league-concert-title {
  width: 100%;
  height: 43px;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(191, 148, 123, 0.5) 0%,
    rgba(173, 124, 97, 0) 20%
  );
  color: #835c46;
  font-size: 16px;
  font-weight: bold;
}
.league-concert-txt {
  font-size: 14px;
  color: #4b4b4b;
  line-height: 24px;
  letter-spacing: 2px;
  text-align: justify;
  margin: 20px 0 8px;
}
.league-concert-img {
  width: 100%;
  display: block;
  margin-top: 20px;
}
</style>
